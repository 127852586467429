import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Banner, Tiles, Container, List } from '../blocks';
import Avatar from '../avatar';
export const wide = true;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  wide,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Login to MPS`}</h1>
    <p>{`Via Syndicate Pass `}<a parentName="p" {...{
        "href": "https://twitter.com/binarybodi"
      }}>{`Connect Wallet`}</a>{` `}<br />{`
Existing Members can login using `}<a parentName="p" {...{
        "href": "https://twitter.com/binarybodi"
      }}>{`Twitter`}</a>{` or `}<a parentName="p" {...{
        "href": "https://twitter.com/binarybodi"
      }}>{`Discord`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      